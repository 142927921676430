import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAuth } from '@/providers/Auth'
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '@/config/routes'

export default function Login() {
    const router = useRouter()
    const authContext = useAuth()
    const isAuthenticated = authContext.isAuthenticated

    useEffect(() => {
        isAuthenticated && router.push(ROUTE_DASHBOARD)
        if ((window?.location?.host || '').startsWith('fornecedores.')) {
            window.location = window.location.href.replace('fornecedores.', 'fornecedor.')
        }

        router.push(ROUTE_LOGIN)
    }, [])

    return <></>
}
